import { Card, Col, Row } from "react-bootstrap"
import { getRequest } from "../../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import '../../styles/card-list/card-list.scss'
import { useState } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Form from 'react-bootstrap/Form';
import { Loader } from '../../components/loader';

export const AllEarnings = () => {

    const { token } = useRecoilValue(userState);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

    const pastEarnings = useQuery({
        queryKey: ['past-earnings', selectedYear],
        queryFn: () => getRequest(`/auth/past-earnings?year=${selectedYear}`, token)
    });
    const { data } = pastEarnings;
    
    return (
        <Loader>
            {data &&
                <Card className="mt-3">
                    <Card.Header className="p-3">
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <h5 className="m-0">Umsatz</h5>
                            </Col>
                            <Col xs={6}>
                                <Form.Select
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    defaultValue={selectedYear}
                                    className="text-end"
                                >
                                    <option className="text-start" value={new Date().getFullYear().toString() - 1}>Vorjahr</option>
                                    <option className="text-start" value={new Date().getFullYear().toString()}>Aktuelles Jahr</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0 h-100">
                    {data  
                    ?
                        <LineChart 
                            data={data}
                            style={{maxWidth: '100%', height: 'unset', paddingTop: '1rem', paddingRight: '2rem'}} 
                            width={1000} 
                            height={400} 
                            >
                            <Line 
                                type="monotone" 
                                dataKey="earnings" 
                                stroke="#0A5ED7" 
                            />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid stroke="#282B2F"/>
                        </LineChart>
                    :
                        <p>Fehler beim Laden der Umsatzdaten.</p>
                    }
                    </Card.Body>
                </Card>
            }
        </Loader>
    )
}