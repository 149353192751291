import { useRecoilValue } from "recoil";
import { Loader } from "../../components/loader"
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../utils/apiRequest";
import { userState } from "../../utils/atom";
import { Col, Container, Row, Toast, ToastContainer } from "react-bootstrap";
import { NewRegistrations } from "../../components/newRegistrations";
import { UpcomingCourses } from "../../components/upcomingCourses";
import { PlannedCourses } from "../../components/plannedCourses";
import { QuickSettings } from "../../components/quickSettings";
import { UpcomingEarnings } from "../../components/dashboard/upcomingEarnings";
import { useState } from "react";
import { AllEarnings } from "../../components/dashboard/allEarnings";
import { News } from "../../components/dashboard/news";
import { PaymentStatistics } from "../../components/dashboard/paymentStatistics";

export const AdminDashboard = () => {

    const { token } = useRecoilValue(userState);
    const userQuery = useQuery({ 
        queryKey: ['userStateQuery'],
        queryFn: () => getRequest('/auth/getuser', token),
    }
    );
    const { data } = userQuery;

    return (
        <>
            <Loader isActive={userQuery.isFetching || userQuery.isLoading}>
                {data &&
                    <>
                        <Container fluid>
                            <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                                <Col 
                                    xs={12}
                                    className="text-center d-flex align-items-center justify-content-center"
                                >
                                    <h5 className="m-0">Willkommen {data.firstName}</h5>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col className="mt-3">
                                    <News isEditable={true}/>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="mt-3">
                                    <QuickSettings />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} lg={6} className="d-flex gap-3 flex-column mb-3">
                                    <NewRegistrations />
                                    <AllEarnings />
                                    <UpcomingEarnings /> 
                                    <PaymentStatistics />
                                </Col>
                                <Col xs={12} lg={6} className="d-flex gap-3 flex-column">
                                    <UpcomingCourses />
                                    <PlannedCourses />
                                </Col>
                            </Row>
                        </Container>
                    </>
                }
            </Loader>
        </>
    )
}