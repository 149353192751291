import { Col, Container, Row } from "react-bootstrap"
import { CalendarPlusFill, Cash, PersonFillAdd, SendPlusFill } from "react-bootstrap-icons"
import '../styles/quick-settings/quick-settings.scss';
import { useNavigate } from "react-router-dom";
import { NewPaymentModal } from "./modals/newPaymentModal";
import { useState } from "react";
import { NewUserModal } from "./modals/newUserModal";

//TODO: ADD LOGIC
export const QuickSettings = () => {

    const navigate = useNavigate();
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [showNewUserModal, setShowNewUserModal] = useState(false);

    return (
        <>
            <Container>
                <Row>
                    <Col className="p-3 border rounded d-flex justify-content-center gap-5">
                        <PersonFillAdd title="Benutzer anlegen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => setShowNewUserModal(true)} />
                        <CalendarPlusFill title="Kurs planen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => navigate('/new-course')}/>
                        <SendPlusFill title="News anlegen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => navigate('/new-news')} />
                        <Cash title="Zahlung erstellen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => setShowNewPaymentModal(true)} />
                    </Col>
                </Row>
            </Container>
            <NewPaymentModal 
                showNewPaymentModal={showNewPaymentModal}
                setShowNewPaymentModal={setShowNewPaymentModal}
            />
            <NewUserModal 
                showNewUserModal={showNewUserModal}
                setShowNewUserModal={setShowNewUserModal}
            />
        </>
    )
}