import { Card, Col, Row } from "react-bootstrap";
import DOMPurify from 'dompurify';

export const SubDetailCard = ({ data }) => {
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col xs={6}>
                        {data.title}
                    </Col>
                    <Col xs={6} className="text-end">
                        {data.subUserType == 'user' ? "Benutzerabo" : "Kinderabo"}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="no-border-on-last-child p-0 overflow-hidden">
                <Row className="border-bottom p-3">
                    <Col xs={6}>Beschreibung:</Col>
                    <Col className="text-end" xs={6}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    data.description.replace(/\n/g, "<br>")
                                ),
                            }}
                        ></div>
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Kosten:
                    </Col>
                    <Col className="text-end" xs={6}>
                        {data.costPerPeriod} €
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Laufzeit:
                    </Col>
                    <Col className="text-end" xs={6}>
                        {data.durationMonths} {`Monat${data.durationMonths > 1 ? "e" : ""}`}
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Zahlungsrhythmus:
                    </Col>
                    <Col className="text-end" xs={6}>
                       {data.paymentRhythm == 'monthly' ? "Monatlich" : "Laufzeit"}
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Abobeginn:
                    </Col>
                    <Col className="text-end" xs={6}>
                       {data.subStart == 'onCreation' ? "Sofort" : "Nächster Monat"}
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Status:
                    </Col>
                    <Col className="text-end" xs={6}>
                        {data.isActive ? 'Aktiv' : 'Inaktiv'}
                    </Col>
                </Row>
                <Row className="border-bottom p-3">
                    <Col xs={6}>
                        Sichtbarkeit:
                    </Col>
                    <Col className="text-end" xs={6}>
                        {data.isPublic ? 'Öffentlich sichtbar' : 'Nicht öffentlich sichtbar'}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
