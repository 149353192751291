import { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { postRequest } from "../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useOutletContext } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Loader } from "../loader";

export const NewUserModal = ({ showNewUserModal, setShowNewUserModal }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        passwordConfirm: "",
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        postalCode: "",
        city: "",
        phone: "",
        isAdmin: false,
        isActive: true,
        hasWhatsAppAllowed: false,
    });

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();

    const [isLoading, setIsLoading] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [sendConfirmationMail, setSendConfirmationMail] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.passwordConfirm) {
            setPasswordMismatch(true);
            return;
        }
        setPasswordMismatch(false);

        setIsLoading(true);
        const response = await postRequest(
            `/register${
                sendConfirmationMail ? "?sendEmail=true" : "?sendEmail=false"
            }`,
            token,
            {
                ...formData,
                password: sha256(formData.password),
            }
        );
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: "Benutzer erfolgreich hinzugefügt. 🥳",
                toastAppearance: "success",
            });
            setShowNewUserModal(false);
        }
        setIsLoading(false);
    };

    return (
        <Loader isActive={isLoading}>
            <Modal
                show={showNewUserModal}
                onHide={() => setShowNewUserModal(false)}
                centered
            >
                <Modal.Header closeButton>Neuen Benutzer anlegen</Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Label>E-Mail-Adresse</Form.Label>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Control
                                    type="email"
                                    placeholder="info@kiddiefy.de"
                                    value={formData.email}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }))
                                    }
                                    required
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Label>Passwort</Form.Label>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Control
                                    type="password"
                                    value={formData.password}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            password: e.target.value,
                                        }))
                                    }
                                    required
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3 border-bottom pb-3">
                            <Col xs={12} md={6}>
                                <Form.Label>Passwort bestätigen</Form.Label>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Control
                                    className={`${
                                        passwordMismatch ? "border-danger" : ""
                                    }`}
                                    type="password"
                                    value={formData.passwordConfirm}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            passwordConfirm: e.target.value,
                                        }))
                                    }
                                    required
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={6} md={6}>
                                <Form.Control
                                    type="text"
                                    placeholder="Vorname"
                                    value={formData.firstName}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            firstName: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>

                            <Col xs={6} md={6}>
                                <Form.Control
                                    type="text"
                                    placeholder="Nachname"
                                    value={formData.lastName}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            lastName: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Straße"
                                    value={formData.street}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            street: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>

                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    placeholder="Nr."
                                    value={formData.streetNumber}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            streetNumber: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    placeholder="PLZ"
                                    value={formData.postalCode}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            postalCode: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>

                            <Col xs={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Stadt"
                                    value={formData.city}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            city: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3 pb-3 border-bottom">
                            <Col xs={12}>
                                <Form.Control
                                    type="text"
                                    placeholder="Telefonnummer"
                                    value={formData.phone}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            phone: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={6}>Whatsapp-Broadcast:</Col>
                            <Col xs={6} className="text-end">
                                <Form.Check
                                    type="switch"
                                    checked={formData.hasWhatsAppAllowed}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            hasWhatsAppAllowed:
                                                !prev.hasWhatsAppAllowed,
                                        }))
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={6}>Benutzer sofort aktiv:</Col>
                            <Col xs={6} className="text-end">
                                <Form.Check
                                    type="switch"
                                    checked={formData.isActive}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            isActive: !prev.isActive,
                                        }))
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={6}>Adminzugang:</Col>
                            <Col xs={6} className="text-end">
                                <Form.Check
                                    type="switch"
                                    checked={formData.isAdmin}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            isAdmin: !prev.isAdmin,
                                        }))
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>

                        <Row className="mb-3 border-bottom pb-3">
                            <Col xs={6}>Bestätigungsmail verschicken:</Col>
                            <Col xs={6} className="text-end">
                                <Form.Check
                                    type="switch"
                                    checked={sendConfirmationMail}
                                    onChange={(e) =>
                                        setSendConfirmationMail((prev) => !prev)
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Form.Control
                                    className="btn btn-primary"
                                    type="submit"
                                    value={"Speichern"}
                                ></Form.Control>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </Loader>
    );
};
