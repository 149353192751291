import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../utils/atom";
import { Login } from "./login";
import { Dashboard } from "./dashboard";
import { Register } from "./register";
import { RegisterFinish } from "./registerFinish";
import { LoginRoot } from "./loginRoot";
import { AdminDashboard } from "./admin/adminDashboard";
import { PageAllRegistrations } from "./admin/pageAllRegistrations";
import { PageUserRegistration } from "./admin/pageUserRegistration";
import { AllPlannedCourses } from "./admin/planned-courses/allPlannedCourses";
import { NewCourse } from "./admin/newCourse";
import { AllActiveCourses } from "./admin/active-courses/allActiveCourses";
import { AllUsers } from "./admin/users/allUsers";
import { User } from "./admin/users/user";
import { AddChildren } from "./user/addChildren";
import { CourseDetail } from "./admin/course-detail/courseDetail";
import { AllActiveCoursesUser } from "./user/allActiveCoursesUser";
import { CourseDetailUser } from "./user/courseDetailsUser";
import { UnpaidEnrollments } from "./admin/payment/unpaidEnrollments";
import { ChildDetail } from "./admin/children/child-detail/childDetail";
import { NewNews } from "./admin/news/newNews";
import { UserProfile } from "./user/user-profile/userProfile";
import { ChildDetailUser } from "./user/child-detail/childDetailUser";
import { EditCourse } from "./admin/edit-course/editCourse";
import { Imprint } from "./imprint";
import { PasswordReset } from "./passwordReset";
import { PasswordResetWithCode } from "./passwordResetWithCode";
import { WaitingRoomUser } from "./user/waiting-room/waitingRoomUser";
import { PastCourses } from "./admin/past-courses/pastCourses";
import { Invoices } from "./invoices/invoices";
import { Subscriptions } from "./subscriptions/subscriptions";
import { SubscriptionDetails } from "./subscriptions/subscriptionDetails";
import { UserSubscriptions } from "./user/subscriptions/userSubscriptions";

export const Root = () => {
    const user = useRecoilValue(userState);

    return user ? (
        <>
            <Routes>
                <Route path="/" element={<LoginRoot />}>
                    <Route path="/imprint" element={<Imprint />} />

                    {user.isAdmin && (
                        <>
                            <Route path="/" element={<AdminDashboard />} />
                            <Route
                                path="/all-registrations"
                                element={<PageAllRegistrations />}
                            />
                            <Route
                                path="/registration/:id"
                                element={<PageUserRegistration />}
                            />

                            <Route
                                path="/all-planned-courses"
                                element={<AllPlannedCourses />}
                            />

                            <Route
                                path="/all-courses"
                                element={<AllActiveCourses />}
                            />

                            <Route
                                path="/past-courses"
                                element={<PastCourses />}
                            />

                            <Route path="/new-course" element={<NewCourse />} />

                            <Route
                                path="/course/:id"
                                element={<CourseDetail />}
                            />

                            <Route
                                path="/edit-course/:id"
                                element={<EditCourse />}
                            />

                            <Route path="/all-users" element={<AllUsers />} />
                            <Route path="/user/:id" element={<User />}></Route>

                            <Route
                                path="/unpaid-enrollments"
                                element={<UnpaidEnrollments />}
                            />

                            <Route
                                path="/child/:id"
                                element={<ChildDetail />}
                            />

                            <Route path="/new-news" element={<NewNews />} />

                            <Route path="/invoice" element={<Invoices />} />

                            <Route path="/subscriptions" element={<Subscriptions />} />
                            <Route path ="/subscription/:id" element={<SubscriptionDetails />} />
                        </>
                    )}
                    {!user.isAdmin && (
                        <>
                            <Route path="/" element={<Dashboard />} />
                            <Route
                                path="/add-children"
                                element={<AddChildren />}
                            />
                            <Route
                                path="/all-courses"
                                element={<AllActiveCoursesUser />}
                            />
                            <Route
                                path="/course/:id"
                                element={<CourseDetailUser />}
                            />
                            <Route path="/profile" element={<UserProfile />} />
                            <Route
                                path="/child/:id"
                                element={<ChildDetailUser />}
                            />
                            <Route
                                path="/waiting-rooms"
                                element={<WaitingRoomUser />}
                            />

                            <Route path="/subscriptions" element={<UserSubscriptions />} />
                        </>
                    )}
                </Route>
            </Routes>
        </>
    ) : (
        <>
            <Routes>
                <Route path="*" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register-finish" element={<RegisterFinish />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/reset-password/:resetCode" element={<PasswordResetWithCode />} />
            </Routes>
        </>
    );
};
