import { ArrowLeft, ChevronDoubleLeft, ChevronDoubleRight } from "react-bootstrap-icons";
import { Loader } from "../../../components/loader";
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { getRequest } from "../../../utils/apiRequest";
import {
    formatDateTimeToDDMMYY
} from "../../../utils/formatDateTime";
import { useEffect, useState } from "react";

export const UnpaidEnrollments = () => {
    const { token } = useRecoilValue(userState);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [paymentsPerPage, setPaymentsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState("date-asc");
    const navigate = useNavigate();

    const unpaidEnrollmentsQuery = useQuery({
        queryKey: ["unpaid-enrollments", page, paymentsPerPage, orderBy],
        queryFn: () =>
            getRequest(
                `/auth/payment/all/unpaid?page=${page}&perPage=${paymentsPerPage}&orderBy=${orderBy}`,
                token
            ),
    });
    const { data } = unpaidEnrollmentsQuery;

    useEffect(() => {
        if (data) {
            const pagesAmount = Math.ceil(data.totalPayments / paymentsPerPage);
            setTotalPages(pagesAmount);
        }
    }, [data, paymentsPerPage]);

    return (
        <Loader
            isActive={
                unpaidEnrollmentsQuery.isLoading ||
                unpaidEnrollmentsQuery.isFetching
            }
        >
            {data && (
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                        <Col
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">Offene Zahlungen</h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    <Container>
                        {data.length < 1 && (
                            <>
                                <Row>
                                    <Col className="text-center mb-3">
                                        Keine offenen Kurskosten. 🥳
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Alert variant="info">
                                            <Alert.Heading>Info</Alert.Heading>
                                            <p className="m-0">
                                                Kurskosten zählen als offen,
                                                sobald ein Kurs in der
                                                Vergangenheit liegt und der
                                                Zahlungsstatus weiterhin "offen"
                                                ist.
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Container>
                            <Row className="justify-content-end bg-dark-subtle rounded p-3 mb-3 gap-3">
                                <Col xs={12} md={5} lg={4} className="pe-0">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Sortieren nach
                                        </InputGroup.Text>
                                        <Form.Select
                                            className="text-end"
                                            value={orderBy}
                                            onChange={(e) =>
                                                setOrderBy(e.target.value)
                                            }
                                        >
                                            <option value={"name-asc"}>
                                                Name aufsteigend
                                            </option>
                                            <option value={"name-desc"}>
                                                Name absteigend
                                            </option>
                                            <option value={"date-asc"}>
                                                Datum aufsteigend
                                            </option>
                                            <option value={"date-desc"}>
                                                Datum absteigend
                                            </option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={5} lg={4} className="pe-0">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Ergebnisse pro Seite
                                        </InputGroup.Text>
                                        <Form.Select
                                            className="text-end"
                                            value={paymentsPerPage}
                                            onChange={(e) =>
                                                setPaymentsPerPage(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            {data.payments.length > 0 &&
                                data.payments.map((payment) => {
                                    return (
                                        <Col
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            className="mb-4"
                                        >
                                            <Card className="h-100">
                                                <Card.Header>
                                                    <Row>
                                                        <Col xs={6}>
                                                            {
                                                                payment.participant
                                                            }
                                                        </Col>
                                                        <Col
                                                            xs={6}
                                                            className="text-end"
                                                        >
                                                            {payment.amount} €
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={4}>
                                                            Zahlungsgrund:
                                                        </Col>
                                                        <Col
                                                            xs={8}
                                                            className="text-end"
                                                        >
                                                            {payment.reason}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={4}>
                                                            Offen seit:
                                                        </Col>
                                                        <Col
                                                            xs={8}
                                                            className="text-end"
                                                        >
                                                            {formatDateTimeToDDMMYY(
                                                                payment.date
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button
                                                        className="me-3"
                                                        onClick={() =>
                                                            navigate(
                                                                `/user/${payment.userId}`
                                                            )
                                                        }
                                                    >
                                                        Elternprofil
                                                    </Button>
                                                    {payment.enrollment && (
                                                        <Button
                                                            onClick={() =>
                                                                navigate(
                                                                    `/course/${payment.enrollment.course.id}`
                                                                )
                                                            }
                                                        >
                                                            Kurs ansehen
                                                        </Button>
                                                    )}
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    );
                                })}
                        </Row>
                        {data.payments.length > 0 && (
                            <Row>
                                <Col
                                    xs={2}
                                    className="d-flex align-items-center"
                                >
                                    {page > 1 && (
                                        <ChevronDoubleLeft
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setPage((prev) => {
                                                    return prev - 1;
                                                })
                                            }
                                        />
                                    )}
                                </Col>
                                <Col xs={8} className="mb-3 mt-3">
                                    <Form.Select
                                        value={page}
                                        onChange={(e) =>
                                            setPage(parseInt(e.target.value))
                                        }
                                    >
                                        {Array.from(
                                            { length: totalPages },
                                            (_, index) => (
                                                <option
                                                    className="text-center"
                                                    key={index + 1}
                                                    value={index + 1}
                                                >
                                                    {`Seite ${
                                                        index + 1
                                                    } von ${totalPages}`}
                                                </option>
                                            )
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col
                                    xs={2}
                                    className="d-flex align-items-center justify-content-end"
                                >
                                    {page < totalPages && (
                                        <ChevronDoubleRight
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setPage((prev) => {
                                                    return prev + 1;
                                                })
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Container>
            )}
        </Loader>
    );
};
