import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { TitleWithBackButton } from "../../components/title-with-back-button/titleWithBackButton";
import { Loader } from "../../components/loader";
import { getRequest } from "../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { SubDetailCard } from "./components/subDetailCard";
import { ActiveSubsCard } from "./components/activeSubsCard";
import { useState } from "react";
import { AddChildToSubscriptionModal } from "./modals/addChildToSubscriptionModal";
import { AddUserToSubscriptionModal } from "./modals/addUserToSubscriptionModal";
import { SubStatsCard } from "./components/subStatsCard";
import { SubPaymentsCard } from "./components/subPaymentsCard";
import { InactiveSubsCard } from "./components/inactiveSubsCard";

export const SubscriptionDetails = () => {
    
    const { token } = useRecoilValue(userState);
    const { id } = useParams();
    
    const [showAddChildModal, setShowAddChildModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    const subModalQuery = useQuery({
        queryKey: ["sub-model-by-id"],
        queryFn: () => getRequest(`/auth/subscription/${id}`, token),
    });
    const { data } = subModalQuery;

    const handleCloseAddChildModal = () => {
        setShowAddChildModal(false);
    }

    const handleCloseAddUserModal = () => {
        setShowAddUserModal(false);
    }

    return (
        <Loader isActive={subModalQuery.isFetching || subModalQuery.isLoading}>
            {data &&
                <>
                    <Container fluid>
                        <TitleWithBackButton title={data.title} />
                    </Container>
                    <Container className="pt-3 pb-3">
                        <Row className="pt-3">
                            <Col xs={12} md={6} className="mb-3">
                                <Stack gap={3}>
                                    <SubDetailCard data={data} />

                                    <ActiveSubsCard 
                                        data={data} 
                                        setShowAddChildModal={setShowAddChildModal}
                                        setShowAddUserModal={setShowAddUserModal}
                                        refetch={subModalQuery.refetch}
                                    />

                                    <InactiveSubsCard 
                                        data={data}
                                        refetch={subModalQuery.refetch}
                                    />

                                </Stack>
                            </Col>
                            <Col xs={12} md={6}>
                                <Stack gap={3}>
                                    <SubStatsCard data={data} />
                                    
                                    <SubPaymentsCard 
                                        subModelId={data.id}
                                    />
                                </Stack>
                                
                            </Col>
                        </Row>
                    </Container>

                    {data.subUserType == 'child'
                        ?                     
                            <AddChildToSubscriptionModal 
                                showAddChildModal={showAddChildModal}
                                handleCloseAddChildModal={handleCloseAddChildModal}
                                subModelData={data}
                                refetch={subModalQuery.refetch}
                            />
                        :
                            <AddUserToSubscriptionModal 
                                showAddUserModal={showAddUserModal}
                                handleCloseAddUserModal={handleCloseAddUserModal}
                                subModelData={data}
                                refetch={subModalQuery.refetch}
                            />
                    }

                </>
            }
        </Loader>
    );
};
