import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Loader } from "../loader";
import { postRequest } from "../../utils/apiRequest";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useOutletContext } from "react-router-dom";

export const UserEnrollment = ({
    show,
    setShow,
    data,
    userData,
    token,
    courseRefetch,
    userRefetch,
}) => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const [allActiveSubscriptions, setAllActiveSubscriptions] = useState([
        ...userData.subscriptions
            .filter((sub) => sub.isActive)
            .map((sub) => sub.subscriptionModelId),
        ...userData.children.flatMap((child) =>
            child.subscriptions
                .filter((sub) => sub.isActive)
                .map((sub) => sub.subscriptionModelId)
        ),
    ]);
    
    const [courseSubscriptions, setCourseSubscriptions] = useState(
        data.subscriptionModels.map((subModel) => subModel.id)
    );
    
    const [formData, setFormData] = useState({
        id: v4(),
        childId: "",
        courseId: data.id,
        cost: data.cost,
        date: data.date,
        paymentStatus: "unpaid",
        paymentType: null,
        paidAt: null,
        userId: userData.id,
    });

    console.log(userData);
    useEffect(() => {
        setError("");
    }, []);

    useEffect(() => {
        setFormData((prev) => {
            const courseDoesNotHaveRestrictions = data.subscriptionModels.length == 0;

            // Check if the user has a matching subscription for the course
            const userHasMatchingSubscription = userData.subscriptions.some(
                (sub) =>
                    sub.isActive &&
                    courseSubscriptions.includes(sub.subscriptionModelId)
            );

            // Find the first eligible child
            const eligibleChild = userData.children.find((child) => {
                // Check if the child has a matching subscription for the course
                const childHasMatchingSubscription = child.subscriptions.some(
                    (sub) =>
                        sub.isActive &&
                        courseSubscriptions.includes(sub.subscriptionModelId)
                );

                // Determine eligibility based on performance level and subscriptions
                return (userHasMatchingSubscription || childHasMatchingSubscription || courseDoesNotHaveRestrictions);
            });

            return {
                ...prev,
                childId: eligibleChild ? eligibleChild.id : "",
            };
        });
    }, [userData, data, courseSubscriptions]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await postRequest(
            "/auth/new-enrollment",
            token,
            formData
        );
        if (response.error) {
            setError(response.error);
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: "Deine Kursanmeldung war erfolgreich. 🥳",
                toastAppearance: "success",
            });
            courseRefetch();
            userRefetch();
            setShow(false);
        }
        setIsLoading(false);
    };

    const handleSelectChange = (event) => {
        setFormData((prev) => {
            return {
                ...prev,
                childId: event.target.value,
            };
        });
    };

    return (
        <Loader isActive={isLoading}>
            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Kursanmeldung</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Form.Label>
                                    Wen möchtest du zum Kurs anmelden?
                                </Form.Label>
                                <Form.Select
                                    onChange={handleSelectChange}
                                    name="childId"
                                    value={formData.childId}
                                >
                                    {userData.children.map((child) => {
                                        
                                        const courseDoesNotHaveRestrictions = data.subscriptionModels.length == 0;
                                        
                                        // Check if the user has a matching subscription for the course
                                        const userHasMatchingSubscription =
                                            userData.subscriptions.some(
                                                (sub) =>
                                                    sub.isActive &&
                                                    courseSubscriptions.includes(
                                                        sub.subscriptionModelId
                                                    )
                                            );

                                        // Check if the child has a matching subscription for the course
                                        const childHasMatchingSubscription =
                                            child.subscriptions.some(
                                                (sub) =>
                                                    sub.isActive &&
                                                    courseSubscriptions.includes(
                                                        sub.subscriptionModelId
                                                    )
                                            );

                                        // Eligibility check
                                        const isEligible = userHasMatchingSubscription || childHasMatchingSubscription || courseDoesNotHaveRestrictions;

                                        if (isEligible) {
                                            return (
                                                <option
                                                    key={child.id}
                                                    value={child.id}
                                                >
                                                    {`${child.firstName} ${child.lastName}`}
                                                </option>
                                            );
                                        }

                                        return null; // Exclude non-eligible children
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={1}>
                                <Form.Check
                                    type="checkbox"
                                    required
                                ></Form.Check>
                            </Col>

                            <Col xs={11}>
                                {data.subscriptionModels.length == 0 ? (
                                    <p className="m-0">
                                        Ich bestätige, die Kurskosten in Höhe
                                        von {data.cost} €. Eine Abmeldung ist
                                        kostenfrei bis zum Vortag des Kurses um
                                        18:00 Uhr möglich. Nach diesem Zeitpunkt
                                        werden bei einer Abmeldung die vollen
                                        Kurskosten in Rechnung gestellt.
                                    </p>
                                ) : (
                                    <p>
                                        Ich bestätige meine Anmeldung zu diesem Kurs, welcher
                                        in meinem Abonnement enthalten ist.
                                    </p>
                                )}
                            </Col>
                        </Row>

                        {error && (
                            <Row>
                                <Col className="mt-3 text-center">
                                    <p className="text-danger">{error}</p>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="submit"
                                    value={"Anmelden"}
                                    className="bg-success border-0"
                                ></Form.Control>
                            </Col>
                            <Col>
                                <Button
                                    className="btn-danger"
                                    onClick={() => setShow(false)}
                                >
                                    Abbrechen
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Loader>
    );
};
