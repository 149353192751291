import { useQuery } from "@tanstack/react-query"
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import { getRequest } from "../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../utils/atom";
import { Loader } from "./loader";
import '../styles/card-list/card-list.scss'
import { useNavigate } from "react-router-dom";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../utils/formatDateTime";
import { shortenString } from "../utils/shortenSting";

export const PlannedCourses = () => {

    const MAX_COURSES_TO_SHOW = 4;
    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();

    const plannedCourses = useQuery({
        queryKey: ['plannedcourses'],
        queryFn: () => getRequest('/auth/course/planned', token)
    });
    const { data } = plannedCourses;

    return (
        <Loader isActive={plannedCourses.isFetching || plannedCourses.isLoading}>
            {data &&
                <Card className="mt-3 p-0">
                    <Card.Header style={{cursor: 'pointer'}} className="p-3" onClick={() => navigate('/all-planned-courses')}>
                        <Row className="align-items-center">
                            <Col xs={12}><h5 className="m-0">Geplante Kurse</h5></Col>
                        </Row>   
                    </Card.Header>
                    <Card.Body className="p-0">
                        {data.courses.length < 1 &&
                            <p className="m-0 p-3">Momentan sind keine weiteren Kurse geplant.</p>
                        }
                        {data.courses.length > 0 &&
                            <ListGroup className="card-list">
                                {data.courses.map((course, index) => {
                                    if (index < MAX_COURSES_TO_SHOW) {
                                        return (
                                            <ListGroupItem 
                                                className="card-list-item"
                                                key={course.id}
                                                onClick={() => navigate(`/course/${course.id}`)}
                                            >
                                                <Row>
                                                    <Col xs={4} className="d-flex text-center justify-content-center align-items-center">
                                                        {shortenString(course.title, 10)}
                                                    </Col>
                                                    <Col xs={4} className="d-flex text-center justify-content-center align-items-center border-start border-end">
                                                        {formatDateTimeToDDMMYY(course.date)}
                                                    </Col>
                                                    <Col xs={4} className="d-flex text-center justify-content-center align-items-center">
                                                        {formatDateTimeToHHMM(course.date)} Uhr
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }
                                })}
                                <ListGroupItem
                                    className="card-list-item"
                                    key="card-list-item-show-more"
                                    onClick={() => navigate('/all-planned-courses')}
                                >
                                    {`Alle anzeigen (${data.courses.length})`}
                                </ListGroupItem>
                            </ListGroup>         
                        }
                        <Col className="text-start border-top p-3">
                            <Button
                                onClick={() => navigate('/new-course')}
                            >
                                Neuen Kurs planen
                            </Button>
                        </Col>
                    </Card.Body>
                </Card>
            }
        </Loader>
    )
}