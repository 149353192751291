import { Button, Col, Modal, Row } from "react-bootstrap";
import { v4 } from "uuid";
import { Loader } from "../../../components/loader";
import { useState } from "react";
import { postRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useOutletContext } from "react-router-dom";
import { PdfDocument } from "../pdf-document/pdfDocument";
import { pdf } from "@react-pdf/renderer";

export const ConfirmSaveModal = ({
    showSaveModal,
    setShowSaveModal,
    invoicePositions,
    selectedUser,
    invoiceNumber,
    invoiceDate,
    taxRate,
    setTriggerReload,
    pdfDataState
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();

    const handleSave = async () => {
        if (invoicePositions.length === 0) {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Rechnung enthält keine Positionen.',
                toastAppearance: 'danger'
            });
            return;
        }

        setIsLoading(true);
        const invoiceId = v4();
        const tax = taxRate === 0 ? "zero" : taxRate === 7 ? "seven" : "nineteen";
        const paymentIds = invoicePositions.map((position) => position.id);

        const data = {
            id: invoiceId,
            date: new Date(invoiceDate),
            number: invoiceNumber,
            payments: paymentIds,
            userId: selectedUser.id,
            includedTax: tax     
        }

        const response = await postRequest('/auth/invoice/save', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
            setShowSaveModal(false);
            setIsLoading(false);
            setTriggerReload((prev) => prev + 1);
        }
        else {
            setTimeout( async () => {
                setShowToast({
                    toastStatus: true,
                    toastMessage: 'Rechnung erfolgreich gespeichert. 💸',
                    toastAppearance: 'success'
                });

                const blob = await pdf(<PdfDocument pdfData={pdfDataState} />).toBlob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `RE-2024-${invoiceNumber}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);

                setShowSaveModal(false);
                setIsLoading(false);
                setTriggerReload((prev) => prev + 1);
            }, 1000);     
        }
    };

    return (
        <Loader isActive={isLoading}>
            <Modal
                show={showSaveModal}
                onHide={() => setShowSaveModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rechnung speichern?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bist du sicher, dass du die Rechnung unveränderlich speichern
                    möchtest?
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col xs={12}>
                            <Button
                                variant="danger"
                                onClick={() => setShowSaveModal(false)}
                            >
                                Abbrechen
                            </Button>
                            <Button 
                                className="ms-3" variant="success"
                                onClick={handleSave}
                            >
                                Speichern
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Loader>
    );
};
