import { Card, Col, Form, Row } from "react-bootstrap";
import { Loader } from "../loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../utils/apiRequest";
import { useState } from "react";
import { formatWithDots } from "../../utils/formatNumbers";

export const PaymentStatistics = () => {

    const { token } = useRecoilValue(userState);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const paymentStats = useQuery({
        queryKey: ['payment-stats', selectedYear],
        queryFn: () => getRequest(`/auth/payment/stats/get?year=${selectedYear}`, token)
    });
    const { data } = paymentStats;

    return (
        <Loader isActive={paymentStats.isLoading || paymentStats.isFetching}>
            {data && (
                <Card className="mt-3">
                    <Card.Header className="p-3">
                        <Row>
                            <Col xs={6} className="d-flex align-items-center">
                                <Card.Title className="m-0">
                                    Zahlungsstatistik
                                </Card.Title>
                            </Col>
                            <Col xs={6}>
                                <Form.Select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                >
                                    <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                    <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Card.Header>

                    <Card.Body className="no-border-on-last-child p-0 overflow-hidden">
                        <Row className="border-bottom p-3">
                            <Col xs={6}>
                                Verbuchte Einnahmen:
                            </Col>
                            <Col xs={6} className="text-end" >
                                {formatWithDots(data.totalSumInvoice)} €
                            </Col>
                        </Row>

                        <Row className="border-bottom p-3">
                            <Col xs={6}>
                                Nicht verbuchte Einnahmen:
                            </Col>
                            <Col xs={6} className="text-end" >
                                {formatWithDots(data.totalSumNoInvoice)} €
                            </Col>
                        </Row>

                        <Row className="border-bottom p-3">
                            <Col xs={6}>
                                Unbezahlte Zahlungen:
                            </Col>
                            <Col xs={6} className="text-end" >
                                {formatWithDots(data.unpaidPayments)} €
                            </Col>
                        </Row>

                        <Row className="border-bottom p-3">
                            <Col xs={6}>
                                Theoretische Kurseinnahmen:
                            </Col>
                            <Col xs={6} className="text-end" >
                                {formatWithDots(data.theoreticalIncome)} €
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            )}
        </Loader>
    );
};
