import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postRequest } from "../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";

export const NewSubModelModal = ({
    showNewSubModelModal,
    handleCloseNewSubModal,
    token,
    refetch,
    data = undefined
}) => {

    const [showToast, setShowToast] = useOutletContext();
    const [formData, setFormData] = useState({
        title: "",
        description: ``,
        costPerPeriod: "",
        durationMonths: 1,
        isActive: false,
        isPublic: false,
        paymentRhythm: "monthly",
        subUserType: "child",
        subStart: 'nextMonth'
    });

    useEffect(() => {
        if (data !== undefined) {
            setFormData({
                id: data.id,
                title: data.title,
                description: data.description,
                costPerPeriod: data.costPerPeriod,
                durationMonths: data.durationMonths,
                isActive: data.isActive,
                isPublic: data.isPublic,
                paymentRhythm: data.paymentRhythm,
                subUserType: data.subUserType,
                subStart: data.subStart
            });
        }
        else {
            setFormData({
                title: "",
                description: ``,
                costPerPeriod: "",
                durationMonths: 1,
                isActive: false,
                isPublic: false,
                paymentRhythm: 'monthly',
                subUserType: 'child',
                subStart: 'nextMonth'
            });
        }
    }, [data]);

    const handleSubmit = async () => {
        const response = await postRequest(`/auth/subscription/model/${data === undefined ? 'new' : 'update'}`, token, formData);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: data === undefined ? 'Abomodell erfolgreich angelegt. 🥳' : 'Abomodell erfolgreich aktualisiert. 🥳',
                toastAppearance: 'success'
            });
            handleCloseNewSubModal();
            refetch();
        }
    }

    return (
        <Modal
            centered
            show={showNewSubModelModal}
            onHide={handleCloseNewSubModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>{data == undefined ? `Neues Abomodell erstellen` : `Abo bearbeiten`}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Row>
                        <Col xs={6}>
                            Abobezeichnung:
                        </Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                value={formData.title}
                                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Beschreibung:
                        </Col>
                        <Col xs={6}>
                            <Form.Control
                                as={"textarea"} rows={3}
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Kosten:
                        </Col>
                        <Col xs={6}>
                            <Form.Control
                                type="text"
                                placeholder="€"
                                value={formData.costPerPeriod}
                                onChange={(e) => setFormData(prev => ({ ...prev, costPerPeriod: e.target.value }))}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Abotyp:
                        </Col>
                        <Col xs={6}>
                            <Form.Select
                                value={formData.subUserType}
                                onChange={(e) => setFormData(prev => ({ ...prev, subUserType: e.target.value }))}
                            >
                                <option value={'child'}>Kinderabo</option>
                                <option value={'user'}>Benutzerabo</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Laufzeit:
                        </Col>
                        <Col xs={6}>
                            <Form.Select
                                value={formData.durationMonths}
                                onChange={(e) => setFormData(prev => ({ ...prev, durationMonths: Number(e.target.value) }))}
                            >
                                <option value={1}>1 Monat</option>
                                <option value={3}>3 Monate</option>
                                <option value={6}>6 Monate</option>
                                <option value={12}>12 Monate</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Zahlungsrhythmus:
                        </Col>
                        <Col xs={6}>
                            <Form.Select
                                value={formData.paymentRhythm}
                                onChange={(e) => setFormData(prev => ({ ...prev, paymentRhythm: e.target.value }))}
                            >
                                <option value={'monthly'}>Monatlich</option>
                                <option value={'duration'}>Laufzeit</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Abobeginn:
                        </Col>
                        <Col xs={6}>
                            <Form.Select
                                value={formData.subStart}
                                onChange={(e) => setFormData(prev => ({ ...prev, subStart: e.target.value }))}
                            >
                                <option value={'onCreation'}>Sofort</option>
                                <option value={'nextMonth'}>Zum nächsten Monat</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Abo ist aktiv:
                        </Col>
                        <Col xs={6}>
                            <Form.Check
                                value={formData.isActive}
                                checked={formData.isActive}
                                onChange={(e) => setFormData(prev => ({ ...prev, isActive: (e.target.checked) }))}
                            >
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            Öffentliches Abo:
                        </Col>
                        <Col xs={6}>
                            <Form.Check
                                value={formData.isPublic}
                                checked={formData.isPublic}
                                onChange={(e) => setFormData(prev => ({ ...prev, isPublic: e.target.checked }))}
                            >
                            </Form.Check>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="danger"
                    onClick={handleCloseNewSubModal}
                >
                    Abbrechen
                </Button>
                <Button
                    variant="success"
                    onClick={handleSubmit}
                >
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
