import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { useEffect, useState } from "react";
import { Loader } from "../../../components/loader";
import { useOutletContext } from "react-router-dom";

export const AddUserToSubscriptionModal = ({
    showAddUserModal,
    handleCloseAddUserModal,
    subModelData,
    refetch
}) => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [subAutoRenew, setSubAutoRenew] = useState(true);

    const handleUserSearch = async () => {
        if (!userFirstName && !userLastName) { return };
        setIsLoading(true);
        const response = await getRequest(`/auth/user?firstname=${userFirstName}&lastname=${userLastName}`, token);
        if (response.length > 0) {
            setUsers(response);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Es konnten keine Kinder gefunden werden.',
                toastAppearance: 'warning'
            });
            setUsers([]);
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        if (!selectedUser.id) { return };
        setIsLoading(true);
        const data = {
            userId: selectedUser.id,
            hasAutoRenew: subAutoRenew,
            ...subModelData
        }
        const response = await postRequest('/auth/subscription/add', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger' 
            });
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: `${userFirstName} wurde zum Abonnement hinzugefügt. 🥳`,
                toastAppearance: 'success' 
            });
            setIsLoading(false);
            handleCloseAddUserModal();
            refetch();
        }
    }

    return (
        <Loader isActive={isLoading}>
            <Modal
                show={showAddUserModal}
                onHide={handleCloseAddUserModal}
                centered
            >
                <Modal.Header>Benutzer zum Abonnement hinzufügen</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Vorname"
                                    value={userFirstName}
                                    onChange={(e) => setUserFirstName(e.target.value)}
                                ></Form.Control>
                                <Form.Control
                                    type="text"
                                    placeholder="Nachname"
                                    value={userLastName}
                                    onChange={(e) => setUserLastName(e.target.value)}
                                ></Form.Control>
                                <Button onClick={handleUserSearch}>Suchen</Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    {users.length > 0 &&
                        <>
                            <Row className="mt-3">
                                <Col xs={12}>Suchergebnisse:</Col>
                            </Row>
                            {users.map((user) => {
                                return (
                                    <Row key={user.id} className="mt-3">
                                        <Col xs={12}>
                                            <Card 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => setSelectedUser(user)}
                                                className={selectedUser?.id == user.id ? 'border-success' : ''}
                                            >
                                                <Card.Header>{user.firstName} {user.lastName}</Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={3}>
                                                            E-Mail:
                                                        </Col>
                                                        <Col xs={9} className="text-end">
                                                            {user.email}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            })}
                            <Row className="mt-3">
                                <Col xs={6}>
                                    Abonnement automatisch verlängern?
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        className="text-end"
                                        type="switch"
                                        checked={subAutoRenew}
                                        onChange={() => setSubAutoRenew(prev => !prev)}
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="me-3"
                        variant="danger"
                        onClick={handleCloseAddUserModal}
                    >
                        Abbrechen
                    </Button>
                    <Button 
                        variant="success"
                        onClick={handleSubmit}
                    >
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        </Loader>
    );
};
