import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { TitleWithBackButton } from "../../components/title-with-back-button/titleWithBackButton";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../utils/apiRequest";
import { Loader } from "../../components/loader";
import { useState } from "react";
import { NewSubModelModal } from "./modals/newSubModelModal";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";

export const Subscriptions = () => {

    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const [showNewSubModelModal, setShowNewSubModelModal] = useState(false);
    const [currentData, setCurrentData] = useState(undefined);

    const subscriptionModelQuery = useQuery({
        queryKey: ['subscriptionModelQuery'],
        queryFn: () => getRequest('/auth/subscription/all-models', token),
    });
    const { data: subModelData } = subscriptionModelQuery;

    const handleCloseNewSubModel = () => {
        setCurrentData(undefined);
        setShowNewSubModelModal(false);
    }

    return (
        <Loader isActive={subscriptionModelQuery.isFetching || subscriptionModelQuery.isLoading}>
            <Container fluid>
                <TitleWithBackButton title={"Abonnementverwaltung"} />
            </Container>
            {subModelData &&
                <Container className="pt-3">
                    {subModelData.length > 0
                        ?   
                            <Row>
                                {subModelData.map((model) => {
                                    return (
                                        <Col xs={12} md={6} lg={6} className="mt-3 mb-3" key={model.id}>
                                            <Card className="h-100">
                                                <Card.Header 
                                                    className="hoverable-title"
                                                    onClick={() => navigate(`/subscription/${model.id}`)}
                                                >
                                                    <Row>
                                                        <Col xs={6}>
                                                            {model.title}
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {model.isActive ? 'Aktiv' : 'Inaktiv'} | {model.isPublic ? <EyeFill title="Öffentlich sichtbar"/> : <EyeSlashFill title="Nicht öffentlich sichtbar"/>}
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12}>
                                                            Beschreibung:
                                                        </Col>
                                                        <Col xs={12}>
                                                            <div 
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(model.description.replace(/\n/g, '<br>'))
                                                                }}                                                            
                                                            >
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button
                                                        variant="success"
                                                        onClick={() => navigate(`/subscription/${model.id}`)}
                                                    >
                                                        Abodetails
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        className="ms-3"
                                                        onClick={() => {
                                                            setCurrentData(model);
                                                            setShowNewSubModelModal(true);
                                                        }}
                                                    >
                                                        Abomodell bearbeiten
                                                    </Button>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>
                        :   
                            <Row className="justify-content-center pt-3">
                                <Col xs={6}>
                                    <Alert className="text-center" variant="info">Keine Abomodelle vorhanden. Erstelle jetzt dein erstes Abomodell.</Alert>
                                </Col>
                            </Row>
                    }
                    <Row className="p-3">
                        <Col xs={6} className="ps-0">
                            <Button
                                onClick={() => setShowNewSubModelModal(true)}
                            >
                                Neues Abomodell erstellen
                            </Button>
                        </Col>
                    </Row>
                    <NewSubModelModal 
                        showNewSubModelModal={showNewSubModelModal}
                        handleCloseNewSubModal={handleCloseNewSubModel}
                        token={token}
                        refetch={subscriptionModelQuery.refetch}
                        data={currentData}
                    />
                </Container>
            }
        </Loader>
    );
};
