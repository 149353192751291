import { getRequest } from "../../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { Loader } from "../../../components/loader";
import { Accordion, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../../utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRepeat, ChevronDoubleLeft, ChevronDoubleRight, PeopleFill } from "react-bootstrap-icons";
import { CourseListingFilter } from "../../../components/listing/course-listing/course-listing-filter/courseListingFilter";

export const AllActiveCourses = () => {
    const { token } = useRecoilValue(userState);
    const [page, setPage] = useState(1);
    const [categories, setCategories] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [showFullCourse, setShowFullCourse] = useState(true);
    const [courses, setCourses] = useState([]);
    const [coursesPerPage, setCoursesPerPage] = useState(5);
    const [showDropdown, setShowDropdown] = useState(false);

    const navigate = useNavigate();

    const allActiveCourses = useQuery({
        queryKey: ["allactivecourses", coursesPerPage, page, categories, dateTo, dateFrom, showFullCourse],
        queryFn: () =>
            getRequest(
                `/auth/course/upcoming?page=${page}&amount=${coursesPerPage}${categories
                    .map((cat) => `&category=${cat.id}`)
                    .join("")}${dateFrom ? `&dateFrom=${dateFrom}` : ""}${dateTo ? `&dateTo=${dateTo}` : ""}${
                    !showFullCourse ? `&hidefull=true` : ""
                }`,
                token
            ),
    });
    let { data } = allActiveCourses;

    const courseTagQuery = useQuery({
        queryKey: ["courseTagQuery"],
        queryFn: () => getRequest("/auth/course/tags", token),
    });
    const { data: courseTags } = courseTagQuery;

    useEffect(() => {
        if (data) {
            const pagesAmount = Math.ceil(data.total / coursesPerPage);
            setTotalPages(pagesAmount);
            setCourses(data.courses);
        }
    }, [data]);

    return (
        <Loader
            isActive={
                allActiveCourses.isFetching ||
                allActiveCourses.isLoading ||
                courseTagQuery.isFetching ||
                courseTagQuery.isLoading
            }
        >
            {data && (
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft size={24} onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                        </Col>
                        <Col xs={8} className="text-center d-flex align-items-center justify-content-center">
                            <h5 className="m-0">Alle aktiven Kurse</h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    <CourseListingFilter
                        categories={categories}
                        setCategories={setCategories}
                        coursesPerPage={coursesPerPage}
                        setCoursesPerPage={setCoursesPerPage}
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        courseTags={courseTags}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        showFullCourse={showFullCourse}
                        setShowFullCourse={setShowFullCourse}
                    />

                    {courses.length < 1 ? (
                        <Row className="mt-3">
                            <Col className="text-center">
                                <p>Zu den aktuellen Filtereinstellungen konnten keine Kurse gefunden werden.</p>
                                <Button onClick={() => navigate("/new-course")}>Neuen Kurs planen</Button>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Container>
                                <Row>
                                    {courses.map((course) => {
                                        return (
                                            <Col xs={12} md={6} lg={4} className="mb-3">
                                                <Card
                                                    className={`mt-3 mb-3 p-0 ${
                                                        course.performanceLevel === 4 && "border-warning"
                                                    } h-100`}
                                                    key={course.id}
                                                >
                                                    <Card.Header className="p-3">
                                                        <Row>
                                                            <Col xs={10}>
                                                                <h5 className="m-0">{course.title}</h5>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Row className="justify-content-end pe-3">
                                                                    {course.performanceLevel === 4 && (
                                                                        <Col xs={6} className="text-end">
                                                                            👑
                                                                        </Col>
                                                                    )}
                                                                    {course.subscriptionModels.length > 0 && (
                                                                        <Col xs={6} className="text-end">
                                                                            <ArrowRepeat
                                                                                title="Dieser Kurs ist Teil eines Abonnements."
                                                                                size={20}
                                                                            />
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body className="p-0">
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>
                                                                Datum:
                                                            </Col>
                                                            <Col className="text-end" xs={9}>
                                                                {formatDateTimeToDDMMYY(course.date)}
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>
                                                                Uhrzeit:
                                                            </Col>
                                                            <Col className="text-end" xs={9}>
                                                                {formatDateTimeToHHMM(course.date)} Uhr
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>
                                                                Ort:
                                                            </Col>
                                                            <Col className="text-end" xs={9}>
                                                                {course.locationName}, {course.locationCity}
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={6}>
                                                                Teilnehmer:
                                                            </Col>
                                                            <Col className="text-end" xs={6}>
                                                                {course.participants.length} | {course.maxParticipants}{" "}
                                                                <PeopleFill />
                                                            </Col>
                                                        </Row>
                                                        {course.waitingRooms !== null && (
                                                            <Row className="m-0 p-3">
                                                                <Col className="p-0" xs={6}>
                                                                    Warteliste:
                                                                </Col>
                                                                <Col className="text-end" xs={6}>
                                                                    {course.waitingRooms.children.length}{" "}
                                                                    {`${
                                                                        course.waitingRooms.children.length === 1
                                                                            ? "Kind"
                                                                            : "Kinder"
                                                                    }`}
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Card.Body>
                                                    <Card.Footer className="p-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Button
                                                                    className="bg-success border-0 w-100"
                                                                    onClick={() => navigate(`/course/${course.id}`)}
                                                                >
                                                                    Kursdetails
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Button
                                                                    className="bg-primary border-0 w-100"
                                                                    onClick={() =>
                                                                        navigate(`/edit-course/${course.id}`)
                                                                    }
                                                                >
                                                                    Bearbeiten
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>

                                {courses.length > 0 && (
                                    <Row>
                                        <Col xs={2} className="d-flex align-items-center">
                                            {page > 1 && (
                                                <ChevronDoubleLeft
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setPage((prev) => {
                                                            return prev - 1;
                                                        })
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col xs={8} className="mb-3 mt-3">
                                            <Form.Select
                                                value={page}
                                                onChange={(e) => setPage(parseInt(e.target.value))}
                                            >
                                                {Array.from({ length: totalPages }, (_, index) => (
                                                    <option className="text-center" key={index + 1} value={index + 1}>
                                                        {`Seite ${index + 1} von ${totalPages}`}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center justify-content-end">
                                            {page < totalPages && (
                                                <ChevronDoubleRight
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setPage((prev) => {
                                                            return prev + 1;
                                                        })
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                        </>
                    )}
                </Container>
            )}
        </Loader>
    );
};
