import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
    formatDateAndTimeToISO,
    formatDateTimeToDDMMYY,
    formatDateTimeToYYYYMMDD,
} from "../../../utils/formatDateTime";
import { useNavigate, useOutletContext } from "react-router-dom";
import { postRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useEffect, useState } from "react";

export const SubscriptionDetailModal = ({
    data,
    showSubscriptionDetailModal,
    setShowSubscriptionDetailModal,
    refetch,
}) => {
    const navigate = useNavigate();
    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();
    const [formData, setFormData] = useState({
        id: data.id,
        hasAutoRenew: data.hasAutoRenew,
        validFrom: formatDateTimeToYYYYMMDD(data.validFrom),
        validUntil: formatDateTimeToYYYYMMDD(data.validUntil),
    });

    const handleCancelSubscription = async () => {
        const response = await postRequest("/auth/subscription/cancel", token, { id: data.id });
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: !data.hasAutoRenew
                    ? "Automatische Verlängerung aktiviert 😊"
                    : "Automatische Verlängerung deaktiviert 🙁",
                toastAppearance: "success",
            });
        }
        setShowSubscriptionDetailModal(false);
        refetch();
    };

    const handleSubmit = async () => {
        const validUntil = formatDateAndTimeToISO(formData.validUntil);
        const validFrom = formatDateAndTimeToISO(formData.validFrom);
        const dataToSubmit = {
            id: formData.id,
            hasAutoRenew: formData.hasAutoRenew,
            validFrom: validFrom,
            validUntil: validUntil,
        };

        const response = await postRequest('/auth/subscription/update', token, dataToSubmit);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Abonnement erfolgreich geupdated. 🥳',
                toastAppearance: "success",
            });
        }
        setShowSubscriptionDetailModal(false);
        refetch();
    };

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            id: data.id,
            validFrom: formatDateTimeToYYYYMMDD(data.validFrom),
            validUntil: formatDateTimeToYYYYMMDD(data.validUntil),
            hasAutoRenew: Boolean(data.hasAutoRenew) 
        }));
    }, [data]);

    return (
        <Modal show={showSubscriptionDetailModal} onHide={() => setShowSubscriptionDetailModal(false)} centered>
            <Modal.Header closeButton>
                {data.user
                    ? `${data.user.firstName} ${data.user.lastName}`
                    : `${data.child.firstName} ${data.child.lastName}`}
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col xs={6}>Abostart:</Col>
                    <Col xs={6} className="text-end">
                        <Form.Control
                            type="date"
                            value={formData.validFrom}
                            onChange={(e) => setFormData((prev) => ({ ...prev, validFrom: e.target.value }))}
                        ></Form.Control>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={6}>Aboende:</Col>
                    <Col xs={6} className="text-end">
                        <Form.Control
                            type="date"
                            value={formData.validUntil}
                            onChange={(e) => setFormData((prev) => ({ ...prev, validUntil: e.target.value }))}
                        ></Form.Control>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={6}>Automatische Verlängerung:</Col>
                    <Col xs={6} className="text-end">
                        <Form.Select
                            value={formData.hasAutoRenew}
                            onChange={(e) => setFormData((prev) => ({ ...prev, hasAutoRenew: e.target.value === "true" }))}
                        >
                            <option value={true}>Aktiv</option>
                            <option value={false}>Inaktiv</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="me-3"
                    onClick={() => {
                        data.user ? navigate(`/user/${data.user.id}`) : navigate(`/child/${data.child.id}`);
                    }}
                >
                    Zum Nutzerprofil
                </Button>
                <Button onClick={handleSubmit} variant="success">Änderungen speichern</Button>
            </Modal.Footer>
        </Modal>
    );
};
