import { Col, Form, Modal, Row } from "react-bootstrap"
import { formatDateAndTimeToISO, formatDateTimeToDDMMYY, formatDateTimeToHHMM, formatDateTimeToYYYYMMDD } from "../../utils/formatDateTime"
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { postRequest } from "../../utils/apiRequest";

export const EnrollmentDetail = ({ show, setShow, data, courseData, token, refetch }) => {

    const [formData, setFormData] = useState({});
    const [showToast, setShowToast] = useOutletContext();
    const [alreadyPaid, setAlreadyPaid] = useState(data.paidAt !== null);

    useEffect(() => {
        setFormData({
            cost: data.cost,
            id: data.id,
            paymentStatus: alreadyPaid ? 'paid' : 'unpaid',
            paymentType: data.paymentType,
            paidAt: data.paidAt ? formatDateTimeToYYYYMMDD(data.paidAt) : null
        })
        setAlreadyPaid(data.paidAt !== null);  
    }, [data]);

    useEffect(() => {
        if (!alreadyPaid) {
            setFormData((prev) => ({
                ...prev, 
                paidAt: null, 
                paymentType: 'cash', 
                paymentStatus: 'unpaid'
            }));
        }
        else {
            setFormData((prev) => ({
                ...prev, 
                paymentStatus: 'paid',
                paymentType: prev.paymentType,
                paidAt: prev.paidAt ? formatDateTimeToYYYYMMDD(prev.paidAt) : formatDateTimeToYYYYMMDD(new Date())          
            }))
        }
    }, [alreadyPaid]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            ...formData,
            paidAt: formData.paidAt == null ? null : formatDateAndTimeToISO(formData.paidAt) 
        }
        const response = await postRequest('/auth/update-enrollment', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Zahlung erfolgreich gespeichert. 💸',
                toastAppearance: 'success'
            });
            refetch();
            setShow(false);
        }
    }

    return (
        <Modal 
            show={show} 
            onHide={() => {setShow(false)}} 
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{data.child.firstName} {data.child.lastName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h5>Zahlung bearbeiten</h5>
                    </Col>
                </Row>

                <Row className="">
                    <Col>
                        Kurs: {courseData.title}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        Ort: {courseData.locationStreet} {courseData.locationStreetNumber}, {courseData.locationPostalCode} {courseData.locationCity}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        Datum: {formatDateTimeToDDMMYY(data.date)}
                    </Col>
                </Row>

                <Row className="border-bottom pb-3">
                    <Col>
                        Uhrzeit: {formatDateTimeToHHMM(data.date)}
                    </Col>
                </Row>

                <Row className="mt-3 ps-3 pe-3">
                    <Col
                        className={
                            `${data.paymentStatus == "paid" ? "bg-success" : "bg-danger"}
                            p-2 rounded
                            `
                        }
                    >
                        {data.paymentStatus == "paid"
                        ?
                            <p className="text-center m-0">Dieser Kurs wurde bereits bezahlt.</p>
                        :
                            <p className="text-center m-0">Kurskosten offen</p>
                        }
                    </Col>
                </Row>

                <Form className="mt-3" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12}>
                            <Form.Label>{"Kurskosten"}:</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.cost}
                                onChange={handleChange}
                                name="cost"
                            ></Form.Control>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={12}>
                            <Form.Check
                                label={"Zahlungseingang verbuchen"}
                                value={alreadyPaid}
                                checked={alreadyPaid}
                                onChange={() => setAlreadyPaid(prev => !prev)}
                            ></Form.Check>
                        </Col>
                    </Row>
                    
                    {alreadyPaid &&
                        <>
                            <Row>
                                <Col xs={12} className="mt-3">
                                    <Form.Label>{"Zahlungsart:"}</Form.Label>
                                    <Form.Select
                                        value={formData.paymentType == 'cash' ? 'cash' : formData.paymentType}
                                        onChange={handleChange}
                                        name="paymentType"
                                    >
                                        <option value={'cash'}>Bar</option>
                                        <option value={'banktransfer'}>Überweisung</option>
                                        <option value={'paypal'}>PayPal</option>
                                        <option value={'card'}>Karte</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="mt-3">
                                    <Form.Label>{"Zahldatum:"}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={formData.paidAt ? formatDateTimeToYYYYMMDD(formData.paidAt) : formatDateTimeToYYYYMMDD(new Date())}
                                        onChange={handleChange}
                                        name="paidAt"
                                    ></Form.Control>
                                </Col>
                            </Row>  
                        </>
                    }

                    <Row>
                        <Col className="mt-3">
                            <Form.Control
                                type="submit"
                                value={"Speichern"}
                                style={{backgroundColor: '#0B6EFD', border: 'none'}}
                            ></Form.Control>
                        </Col>
                    </Row>

                </Form>
                
            </Modal.Body>
        </Modal>
    )
}