import { Col, Container, Row } from "react-bootstrap";
import { TitleWithBackButton } from "../../components/title-with-back-button/titleWithBackButton";
import { Loader } from "../../components/loader";
import "./invoices.scss";
import { InvoiceSidebar } from "./invoice-sidebar/invoiceSidebar";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../utils/apiRequest";
import { useEffect, useState } from "react";
import { NewInvoiceTab } from "./new-invoice-tab/newInvoiceTab";

export const Invoices = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [invoicePositions, setInvoicePositions] = useState([]);
    const [triggerReload, setTriggerReload] = useState(0);
    const [taxRate, setTaxRate] = useState(0);

    const { token } = useRecoilValue(userState);
    const userData = useQuery({
        queryKey: ["all-users-invoice", triggerReload],
        queryFn: () => getRequest(`/auth/all-users`, token),
        refetchOnWindowFocus: false,
    });
    const { data } = userData;

    const lastInvoice = useQuery({
        queryKey: ["last-invoice", triggerReload],
        queryFn: () => getRequest(`/auth/invoice/lastnumber`, token),
        refetchOnWindowFocus: false,
    });
    const { data: lastInvoiceData } = lastInvoice;

    useEffect(() => {
        setInvoicePositions([]);
    }, [triggerReload, selectedUser]);

    return (
        <Loader
            isActive={(userData.isLoading || userData.isFetching) && (lastInvoice.isLoading || lastInvoice.isFetching)}
        >
            <Container fluid className="mb-0">
                <TitleWithBackButton
                    title={"Rechnungen"}
                    customBackFunction={setSelectedUser}
                    customBackFunctionArgument={null}
                    customBackFunctionEnabled={selectedUser !== null}
                />
            </Container>
            {userData && lastInvoice && (
                <Container fluid className="invoice-container">
                    <Row className="invoice-container-inner">
                        <Col xs={2} className="border-end invoice-sidebar">
                            <InvoiceSidebar
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                invoicePositions={invoicePositions}
                                setInvoicePositions={setInvoicePositions}
                            />
                        </Col>
                        <Col xs={10} className="invoice-main">
                            <NewInvoiceTab
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                invoicePositions={invoicePositions}
                                setInvoicePositions={setInvoicePositions}
                                data={data}
                                lastInvoiceNumber={lastInvoiceData}
                                refetchAllUsers={userData.refetch}
                                refetchLastInvoice={lastInvoice.refetch}
                                setTriggerReload={setTriggerReload}
                                taxRate={taxRate}
                                setTaxRate={setTaxRate}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </Loader>
    );
};
