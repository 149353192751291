import { Card, Col, Row } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { Loader } from "../../../components/loader";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";

export const SubStatsCard = ({ data }) => {
    
    const { token } = useRecoilValue(userState);
    
    const returningEarningsQuery = useQuery({
        queryKey: ["returning-earnings"],
        queryFn: () =>
            getRequest(`/auth/subscription/stats/returning/${data.id}`, token),
    });
    const { data: subStats } = returningEarningsQuery;

    return (
        <Loader isActive={returningEarningsQuery.isFetching || returningEarningsQuery.isLoading}>
            {subStats &&
                <Card>
                    <Card.Header>Abostatistiken</Card.Header>
                    <Card.Body className="p-0 no-border-on-last-child overflow-hidden">
                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Aktive Abonnements:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.active}
                            </Col>
                        </Row>

                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Inaktive Abonnements:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.inActive}
                            </Col>
                        </Row>

                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Demnächst startende Abonnements:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.inQueue}
                            </Col>
                        </Row>

                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Auslaufende Abonnements:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.canceled}
                            </Col>
                        </Row>

                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Wiederkehrende Einnahmen:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.returning} €
                            </Col>
                        </Row>

                        <Row className="p-3 border-bottom">
                            <Col xs={6}>
                                Gesamteinnahmen:
                            </Col>
                            <Col xs={6} className="text-end">
                                {subStats.totalEarnings} €
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            }
        </Loader>
    );
};
