import { Button, Card, Col, Row } from "react-bootstrap";
import { ArrowClockwise, PauseCircle, PersonFillAdd, PlayCircle, SlashCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { formatDateTimeToDDMMYYYY } from "../../../utils/formatDateTime";
import { useState } from "react";
import { SubscriptionDetailModal } from "../modals/subscriptionDetailModal";

export const InactiveSubsCard = ({ data, refetch }) => {

    const navigate = useNavigate();
    const [showSubscriptionDetailModal, setShowSubscriptionDetailModal] = useState(false);
    const [subDetailIndex, setSubDetailIndex] = useState(-1);

    return (
        <Card className="mt-3">
            <Card.Header>
                <Row>
                    <Col xs={12}>
                        Inaktive Abonnements
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="no-border-on-last-child p-0 overflow-hidden">
                {data.subscriptions.filter((sub) => !sub.isActive && new Date(sub.validFrom) <= new Date()).length > 0 &&
                    data.subscriptions.map((sub, index) => {
                        if (!sub.isActive && new Date(sub.validFrom) <= new Date()) {                      
                            return (
                                <Row 
                                    key={sub.id} 
                                    className="border-bottom p-3 hoverable-title"
                                    onClick={() => {
                                        setSubDetailIndex(index);
                                        setShowSubscriptionDetailModal(true);
                                    }}
                                >
                                    <Col xs={6}>
                                        {data.subUserType == 'user'
                                            ? `${sub.user.firstName} ${sub.user.lastName}`
                                            : `${sub.child.firstName} ${sub.child.lastName}`
                                        }
                                    </Col>
                                    <Col xs={6} className="text-end">
                                        <Row className="justify-content-end" style={{flexWrap: 'nowrap'}}>
                                            <Col xs={10} className="border-end">
                                                {sub.isActive
                                                    ? <span className="text-success">Aktiv</span>
                                                    : `Abo endete am ${formatDateTimeToDDMMYYYY(sub.validUntil)}`
                                                }
                                            </Col>
                                            <Col xs={2} style={{width: 'fit-content'}}>
                                                {sub.hasAutoRenew
                                                    ? <PlayCircle title="Automatische Verlängerung aktiv" />
                                                    : <PauseCircle title="Automatische Verlängerung deaktiviert" />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }
                    })
                }
                {data.subscriptions.filter(sub => !sub.isActive && new Date(sub.validFrom) <= new Date()).length == 0 &&
                    <Row className="p-3">
                        <Col xs={12}>
                            Keine inaktiven Abonnements.
                        </Col>
                    </Row>
                }
                {subDetailIndex > -1 &&
                    <SubscriptionDetailModal 
                        data={data.subscriptions[subDetailIndex]}
                        showSubscriptionDetailModal={showSubscriptionDetailModal}
                        setShowSubscriptionDetailModal={setShowSubscriptionDetailModal}
                        refetch={refetch}
                    />
                }
            </Card.Body>
        </Card>
    );
};
