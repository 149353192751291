import { Col, Modal, Row } from "react-bootstrap"
import { Copy } from "react-bootstrap-icons";
import { useOutletContext } from "react-router-dom";

export const BankDetailModal = ({ show, hide, user }) => {

    const [showToast, setShowToast] = useOutletContext();

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShowToast({
                    toastStatus: true,
                    toastMessage: 'In die Zwischenablage kopiert ✅',
                    toastAppearance: 'success'
                })
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
    <Modal show={show} onHide={hide} centered>
        <Modal.Header closeButton>
            <Modal.Title>Bankverbindung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={6}>
                    Kontoinhaber:
                </Col>
                <Col 
                    xs={6}
                    style={{cursor: 'pointer'}}
                    onClick={() => {handleCopy('René Andreas Schmidt')}}
                >
                    <Row>
                        <Col xs={9}>
                            René Andreas Schmidt
                        </Col>
                        <Col xs={3}>
                            <Copy />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={6}>
                    IBAN:
                </Col>
                <Col 
                    xs={6}
                    style={{cursor: 'pointer'}}
                    onClick={() => {handleCopy('DE56240501100066126806')}}
                >
                    <Row>
                        <Col xs={9}>
                            DE56 2405 0110 0066 1268 06
                        </Col>
                        <Col xs={3}>
                            <Copy />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={6}>
                    BIC:
                </Col>
                <Col 
                    xs={6}
                    style={{cursor: 'pointer'}}
                    onClick={() => {handleCopy('NOLADE21LBG')}}
                >
                    <Row>
                        <Col xs={9}>
                            NOLADE21LBG
                        </Col>
                        <Col xs={3}>
                            <Copy />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={6}>
                    Verwendungszweck:
                </Col>
                <Col 
                    xs={6}
                    style={{cursor: 'pointer'}}
                    onClick={() => {handleCopy(`Gesamtrechnung ${user.firstName} ${user.lastName}, ${user.id.split('-')[0]}`)}}
                >
                    <Row>
                        <Col xs={9}>
                            {`Gesamtrechnung ${user.firstName} ${user.lastName}, ${user.id.split('-')[0]}`}
                        </Col>
                        <Col xs={3}>
                            <Copy />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
    )
}