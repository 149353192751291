import {
    Container,
    Row,
    Col,
    Accordion,
    Form,
    DropdownButton,
    Dropdown,
    Button,
    InputGroup,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { shortenString } from "../../../../utils/shortenSting";
import { useEffect, useState } from "react";

export const CourseListingFilter = ({
    categories,
    setCategories,
    coursesPerPage,
    setCoursesPerPage,
    courseTags,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    showDropdown,
    setShowDropdown,
    showFullCourse,
    setShowFullCourse,
}) => {
    const [screenWidth, setScreenWidth] = useState(0);

    const handleTagToggle = (tag) => {
        setCategories((prevSelectedTags) => {
            const tagIndex = prevSelectedTags.findIndex(
                (selectedTag) => selectedTag.id === tag.id
            );
            if (tagIndex > -1) {
                return prevSelectedTags.filter(
                    (selectedTag) => selectedTag.id !== tag.id
                );
            } else {
                return [...prevSelectedTags, { id: tag.id, name: tag.name }];
            }
        });
    };

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, []);

    return (
        <Container>
            <Accordion defaultActiveKey={1}>
                <Accordion.Item eventKey={1}>
                    <Accordion.Header>Filter</Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        Kategorien:
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <DropdownButton
                                            show={showDropdown}
                                            onToggle={() =>
                                                setShowDropdown((prev) => !prev)
                                            }
                                            title="Kategorien"
                                            className="listing-filter-dropdown"
                                        >
                                            {courseTags.map((tag) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={tag.id}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleTagToggle(
                                                                tag
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Form.Check
                                                            label={tag.name}
                                                            checked={categories.some(
                                                                (category) =>
                                                                    category.id ===
                                                                    tag.id
                                                            )}
                                                            onChange={() =>
                                                                handleTagToggle(
                                                                    tag
                                                                )
                                                            }
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        ></Form.Check>
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        Kurse pro Seite:
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Select
                                            value={coursesPerPage}
                                            onChange={(e) =>
                                                setCoursesPerPage(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        Ab Datum:
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Control
                                            type="date"
                                            onChange={(e) =>
                                                setDateFrom(e.target.value)
                                            }
                                            value={dateFrom}
                                        ></Form.Control>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        Bis Datum:
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Control
                                            type="date"
                                            onChange={(e) =>
                                                setDateTo(e.target.value)
                                            }
                                            value={dateTo}
                                        ></Form.Control>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="mt-3">
                                <Form.Check
                                    label="Volle Kurse ausblenden"
                                    type="switch"
                                    checked={!showFullCourse}
                                    onChange={() =>
                                        setShowFullCourse((prev) => !prev)
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>
                        {categories.length > 0 && (
                            <Row>
                                <Col
                                    xs={12}
                                    className="mt-3 d-flex flex-wrap gap-3"
                                    style={{ alignItems: "flex-start" }}
                                >
                                    {categories.map((category) => {
                                        return (
                                            <Button
                                                key={category.id}
                                                onClick={() =>
                                                    handleTagToggle(category)
                                                }
                                                style={{
                                                    background: "#D63384",
                                                    borderColor: "#D63384",
                                                    minWidth: "120px",
                                                    maxWidth: "150px",
                                                }}
                                            >
                                                {shortenString(
                                                    category.name,
                                                    screenWidth > 991 ? 10 : 8
                                                )}{" "}
                                                <X
                                                    style={{
                                                        marginBottom: "3px",
                                                    }}
                                                />
                                            </Button>
                                        );
                                    })}
                                </Col>
                            </Row>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
};
