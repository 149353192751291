import { Card, Col, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";
import { UpdatePaymentModal } from "../../../components/modals/updatePaymentModal";
import { useState } from "react";

export const SubPaymentsCard = ({ subModelId }) => {

    const { token } = useRecoilValue(userState);
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
    const [paymentId, setPaymentId] = useState('');

    const subPaymentsQuery = useQuery({
        queryKey: ["sub-unpaid"],
        queryFn: () => getRequest(`/auth/subscription/unpaid/${subModelId}`, token),
    });
    const { data } = subPaymentsQuery;

    return (
        <Loader isActive={subPaymentsQuery.isLoading || subPaymentsQuery.isFetching}>
            {data &&
                <Card className="mt-3">
                    <Card.Header>Offene Abozahlungen</Card.Header>
                    <Card.Body className="no-border-on-last-child p-0 overflow-hidden">
                        {data.length > 0 &&
                            (data.map(payment => (
                                <Row
                                    key={payment.Id}
                                    className="hoverable-title p-3 border-bottom"
                                    onClick={() => {
                                        setPaymentId(payment.id);
                                        setShowUpdatePaymentModal(true);
                                    }}
                                >
                                    <Col xs={4} className="border-end">
                                        {payment.user.firstName} {payment.user.lastName}
                                    </Col>
                                    <Col xs={4} className="border-end text-center">
                                        {payment.participant}
                                    </Col>
                                    <Col xs={4} className="text-end">
                                        {formatDateTimeToDDMMYY(payment.date)}
                                    </Col>
                                </Row>
                            )))
                        }
                        {data.length == 0 &&
                            <Row className="p-3">
                                <Col xs={12}>
                                    Keine offenen Abozahlungen.
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                    {paymentId !== '' &&
                        <UpdatePaymentModal 
                            showUpdatePaymentModal={showUpdatePaymentModal}
                            setShowUpdatePaymentModal={setShowUpdatePaymentModal}
                            paymentId={paymentId}
                        />
                    }
                </Card>
            }
        </Loader>
    );
};
