import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../components/loader";
import { ArrowLeft } from "react-bootstrap-icons";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../utils/formatDateTime";
import '../../styles/card-list/card-list.scss';
import { useState } from "react";
import { UserEnrollment } from "../../components/modals/userEnrollment";
import { WaitingRoomEnrollmentModal } from "../../components/modals/waitingRoomEnrollmentModal";
import { PaySingleCourseModal } from "../../components/modals/paySingleCourseModal";
import { shortenString } from "../../utils/shortenSting";

export const CourseDetailUser = () => {

    const { id } = useParams();
    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showWaitingRoomEnrollment, setShowWaitingRoomEnrollment] = useState(false);
    const [showPaySingleCourseModal, setShowPaySingleCourseModal] = useState(false);

    const courseDetailQuery = useQuery({
        queryKey: ['course-detail-user'],
        queryFn: () => postRequest(`/auth/course-detail-user`, token, {id: id})
    });
    const { data } = courseDetailQuery;

    const userQuery = useQuery({
        queryKey: ['userStateQueryDetail'],
        queryFn: () => getRequest('/auth/getuser', token),
    });
    const { data: userData } = userQuery;

    const performanceMapping = {
        0: 'Babyschwimmen',
        1: 'Wassergewöhnung',
        2: 'Anfänger',
        35: 'Gemischt',
        3: 'Fortgeschritten',
        4: 'Leistungskurs'
    }

    return (
        <Loader isActive={courseDetailQuery.isFetching || courseDetailQuery.isLoading || userQuery.isFetching || userQuery.isLoading}>
            {data && userData &&
                <>
                    <Container fluid>
                        <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                            <Col xs={2} className="p-0">
                                <ArrowLeft 
                                    size={24}
                                    onClick={() => navigate(-1)}
                                    style={{cursor: 'pointer'}}
                                />
                            </Col>
                            <Col 
                                xs={8}
                                className="text-center d-flex align-items-center justify-content-center"
                            >
                                <h5 className="m-0">{data.title}</h5>
                            </Col>
                            <Col xs={2}>
                                
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        {new Date(data.date) < new Date() &&
                            <>  
                                <Row>
                                    <Col className="mt-3">
                                        <Alert variant="info">
                                            <Row className="text-center">
                                                <Col xs={12}>
                                                    Dieser Kurs liegt in der Vergangenheit.
                                                </Col>
                                                <Col xs={12}>
                                                    Eine Anmeldung zu diesem Kurs ist nicht mehr möglich.
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </Col>
                                </Row>

                                {userData.children.some((child) => {
                                    return child.enrolledCourses.some((course) => {
                                        return course.courseId === id && course.paymentStatus === 'unpaid';
                                        });
                                }) &&
                                    <Row>
                                        <PaySingleCourseModal 
                                            show={showPaySingleCourseModal}
                                            hide={() => setShowPaySingleCourseModal(false)}
                                            data={userData}
                                            courseId={id} 
                                        />

                                        <Col className="mt-3">
                                            <Alert variant="danger">
                                                <Row className="text-center">
                                                    <Col xs={12}>
                                                        Für diesen Kurs liegen noch offene Kurskosten vor.
                                                    </Col>
                                                    <Col cs={12} className="mt-3">
                                                        <Button 
                                                            className="btn-danger"
                                                            onClick={() => setShowPaySingleCourseModal(true)}
                                                        >
                                                            Jetzt bezahlen
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }
                        <Row>
                            <Col className="mt-3 mb-3">
                                <Card>
                                    <Card.Header>Kursdetails</Card.Header>
                                    <Card.Body>
                                        <Row className="border-bottom pb-3">
                                            <Col xs={3}>
                                                Leistungsniveau:
                                            </Col>
                                            <Col xs={9} className="text-end">
                                                {performanceMapping[data.performanceLevel]}
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom pt-3 pb-3">
                                            <Col xs={3}>
                                                Ort:
                                            </Col>
                                            <Col xs={9} className="text-end">
                                                {data.locationName}
                                            </Col>
                                        </Row>

                                        <Row className="border-bottom pt-3 pb-3">
                                            <Col xs={6}>
                                                Datum:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {formatDateTimeToDDMMYY(data.date)}
                                            </Col>
                                        </Row>

                                        <Row className="border-bottom pt-3 pb-3">
                                            <Col xs={6}>
                                                Uhrzeit:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {formatDateTimeToHHMM(data.date)} Uhr
                                            </Col>
                                        </Row>

                                        <Row className="pt-3 pb-3 border-bottom">
                                            <Col xs={3}>
                                            Adresse:
                                            </Col>
                                            <Col xs={9} className="text-end">
                                                <a
                                                    style={{textDecoration: 'none'}}
                                                    target="_blank"
                                                    href={`https://www.google.de/maps/place/${data.locationStreet}+${data.locationStreetNumber}+${data.locationPostalCode}+${data.locationCity}`}
                                                >
                                                    {data.locationStreet} {data.locationStreetNumber}, {data.locationCity}
                                                </a>
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col xs={4}>
                                                Kategorien:
                                            </Col>
                                            <Col xs={8}>
                                                <Row className="gap-3 justify-content-end">
                                                    {data.courseTags.map((tag) => {
                                                        return (
                                                            <Col xs={12} md={6} lg={3} key={tag.id}>
                                                                <Button 
                                                                    style={{
                                                                        backgroundColor: '#D63384',
                                                                        borderColor: '#D63384',
                                                                        cursor: 'default'
                                                                    }}
                                                                    className="w-100"
                                                                    title={tag.name}
                                                                >
                                                                        {shortenString(tag.name, 10)}
                                                                </Button>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>             

                        <Row>
                            <Col className="mt-3 mb-3">
                                <Card>
                                    <Card.Header>Lehrer</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} className="text-start">
                                                <p className="m-0">{data.courseTeacher}</p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> 

                        <Row>
                            <Col className="mt-3 mb-3">
                                <Card>
                                    <Card.Header>Kurskosten</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} className="text-start">
                                                {`${data.cost} € pro Teilnehmer`}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> 

                        <Row>
                            <Col className="mt-3 mb-3">
                                <Card>
                                    <Card.Header>Infos des Veranstalters</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} className="text-start">
                                                {data.courseInfo
                                                ?
                                                    <p className="m-0">{data.courseInfo}</p>
                                                :
                                                    <p className="m-0">Der Veranstalter hat keine zusätzlichen Informationen angegeben.</p>
                                                }
                                                
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>     
                        
                        {new Date(data.date) >= new Date() &&
                            (userData.children.length > 0 
                            ?
                                <Row>
                                    <Col className="mt-3 mb-3">
                                        {data.maxParticipants - data.participants > 0
                                        ?
                                            <Row className="ps-3 pe-3">
                                                <Col xs={12} className="text-center bg-success pt-2 pb-2 rounded">
                                                    {`Noch ${data.maxParticipants - data.participants > 1 ? `${data.maxParticipants - data.participants} freie Plätze` : `${data.maxParticipants - data.participants} freier Platz`}`}
                                                </Col>

                                                <Col xs={12} className="mt-3 mb-3 p-0">
                                                    <Button 
                                                        className="w-100"
                                                        onClick={() => setShow(true)}
                                                    >
                                                        An Kurs teilnehmen
                                                    </Button>
                                                </Col>
                                            </Row>
                                        :   
                                            <Row>
                                                <Col xs={12} className="text-center bg-danger pt-2 pb-2 rounded">
                                                    {`Dieser Kurs ist bereits voll. :(`}
                                                </Col>

                                                <Col xs={12} className="mt-3 mb-3 p-0">
                                                    <Button 
                                                        className="w-100"
                                                        onClick={() => setShowWaitingRoomEnrollment(true)}
                                                        >
                                                            In Warteliste eintragen
                                                        </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>     
                            :
                                <Row className="text-center">
                                    <Col className="mt-3 mb-3">
                                        <p className="text-danger">Du hast noch keine Kinder zur Teilnahme an unseren Kursen angelegt.</p>
                                        <Button
                                            onClick={() => navigate('/add-children')}
                                        >
                                            Jetzt Kursteilnehmer anlegen
                                        </Button>
                                    </Col>
                                </Row>
                            ) 
                        }
                        
                        {(userData.children.length > 0 && data.maxParticipants - data.participants > 0)
                        ?    
                            <UserEnrollment 
                                show={show}
                                setShow={setShow}
                                data={data}
                                userData={userData}
                                token={token}
                                courseRefetch={courseDetailQuery.refetch}
                                userRefetch={userQuery.refetch}
                            />
                        : userData.children.length > 0 &&
                            <WaitingRoomEnrollmentModal 
                                showWaitingRoomEnrollment={showWaitingRoomEnrollment}
                                setShowWaitingRoomEnrollment={setShowWaitingRoomEnrollment}
                                data={data}
                                userData={userData}
                                token={token}
                                courseRefetch={courseDetailQuery.refetch}
                                userRefetch={userQuery.refetch}
                            />
                        }
                    </Container>
                </>
            }
        </Loader>
    )
}