import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Loader } from "../loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import SearchableDropdownGeneral from "../utilities/searchableDropdownGeneral";
import { useEffect, useState } from "react";
import { formatDateTimeToYYYYMMDD } from "../../utils/formatDateTime";
import { v4 } from "uuid";
import { useOutletContext } from "react-router-dom";

export const NewPaymentModal = ({
    showNewPaymentModal,
    setShowNewPaymentModal,
    userId = null,
    enrollment = null,
    refetch = null
}) => {
    const { token } = useRecoilValue(userState);
    const userData = useQuery({
        queryKey: ["all-users"],
        queryFn: () => getRequest(`/auth/all-users`, token),
        enabled: userId == null
    });
    const { data } = userData;

    const userDataById = useQuery({
        queryKey: ["user-by-id", userId],
        queryFn: () => getRequest(`/auth/getuserbyid/${userId}`, token),
        enabled: userId !== null
    });
    const { data: userByIdData } = userDataById;

    const [showToast, setShowToast] = useOutletContext();

    const [selectedUser, setSelectedUser] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [alreadyPaid, setAlreadyPaid] = useState(false);
    const [error, setError] = useState(false);

    const initializePaymentData = () => ({
        id: v4(),
        date: formatDateTimeToYYYYMMDD(new Date()),
        amount: "",
        enrollment: enrollment,
        userId: selectedUser?.id,
        reason: "",
        participant: "",
        status: alreadyPaid ? "paid" : "unpaid",
        paidAt: alreadyPaid ? formatDateTimeToYYYYMMDD(new Date()) : '',
        paymentType: 'cash',
    });

    const resetForm = () => {
        setSelectedUser(null);
        setPaymentData(initializePaymentData());
        setAlreadyPaid(false);
        setError(false);
    };

    useEffect(() => {
        setPaymentData(initializePaymentData());
    }, [selectedUser]);

    useEffect(() => {
        setPaymentData((prev) => {
            return {
                ...prev,
                status: alreadyPaid ? "paid" : "unpaid",
            };
        });
    }, [alreadyPaid]);

    useEffect(() => {
        if (userByIdData) {
            setSelectedUser(userByIdData);
        }
    }, [userByIdData]);

    const handleSubmit = async () => {
        if (
            !selectedUser ||
            paymentData.amount === "" ||
            paymentData.reason === ""
        ) {
            setError(true);
            return;
        }

        const response = await postRequest(
            "/auth/payment/new-payment",
            token,
            paymentData
        );
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: "Fehler beim Anlegen der Zahlung",
                toastAppearance: "danger",
            });
        } else {
            setShowToast({
                toastStatus: true,
                toastMessage: "Zahlung erfolgreich angelegt 💵",
                toastAppearance: "success",
            });
            setShowNewPaymentModal(false);
            resetForm();
            if (refetch) {
                refetch();
            }
        }
    };

    return (
        <Loader
            isActive={
                userData.isFetching ||
                userData.isLoading ||
                userByIdData?.isFetching ||
                userByIdData?.isLoading
            }
        >
            {(data || userByIdData) && (
                <Modal
                    show={showNewPaymentModal}
                    onHide={() => {
                        resetForm();
                        setShowNewPaymentModal(false);
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Neue Zahlung erstellen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                {!userId &&
                                    <SearchableDropdownGeneral
                                        optionsArray={data.users}
                                        selectedUser={selectedUser}
                                        setSelectedUser={setSelectedUser}
                                    />
                                }
                                {(userId && selectedUser) &&
                                    <>
                                        <Row className="mb-3">
                                            <Col xs={6}>
                                                Benutzer:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {selectedUser.firstName} {selectedUser.lastName}
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Zahlungsgrund:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    className="text-end"
                                    value={paymentData?.reason}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({
                                            ...prev,
                                            reason: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Teilnehmer:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    className="text-end"
                                    value={paymentData?.participant}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({
                                            ...prev,
                                            participant: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Betrag:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="text"
                                    placeholder="€"
                                    className="text-end"
                                    value={paymentData?.amount}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({
                                            ...prev,
                                            amount: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Datum:</Col>
                            <Col xs={6}>
                                <Form.Control
                                    type="date"
                                    value={paymentData?.date}
                                    onChange={(e) =>
                                        setPaymentData((prev) => ({
                                            ...prev,
                                            date: e.target.value,
                                        }))
                                    }
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>Bereits bezahlt?</Col>
                            <Col xs={6}>
                                <Form.Check
                                    checked={alreadyPaid}
                                    onChange={() =>
                                        setAlreadyPaid((prev) => !prev)
                                    }
                                ></Form.Check>
                            </Col>
                        </Row>
                        {alreadyPaid && (
                            <>
                                <Row className="mt-3">
                                    <Col xs={6}>Bezahlt am:</Col>
                                    <Col xs={6}>
                                        <Form.Control
                                            type="date"
                                            value={paymentData?.paidAt}
                                            onChange={(e) =>
                                                setPaymentData((prev) => ({
                                                    ...prev,
                                                    paidAt: e.target.value,
                                                }))
                                            }
                                        ></Form.Control>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={6}>Zahlungsart:</Col>
                                    <Col xs={6}>
                                        <Form.Select
                                            value={paymentData.paymentType ? paymentData.paymentType : 'cash'}
                                            onChange={(e) =>
                                                setPaymentData((prev) => ({
                                                    ...prev,
                                                    paymentType: e.target.value,
                                                }))
                                            }
                                        >
                                            <option value={"cash"}>Bar</option>
                                            <option value={"paypal"}>
                                                PayPal
                                            </option>
                                            <option value={"banktransfer"}>
                                                Überweisung
                                            </option>
                                            <option value={"card"}>
                                                Karte
                                            </option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {error && (
                            <Row>
                                <Col xs={12}>
                                    <Alert
                                        className="mt-3 text-center"
                                        variant="danger"
                                    >
                                        <p className="m-0">
                                            Nicht alle notwendigen Felder
                                            ausgefüllt
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col xs={12}>
                                <Button
                                    onClick={() =>
                                        setShowNewPaymentModal(false)
                                    }
                                    variant="danger"
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    variant="success"
                                    className="ms-3"
                                    onClick={handleSubmit}
                                >
                                    Speichern
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            )}
        </Loader>
    );
};

