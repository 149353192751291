import { Col, Row } from "react-bootstrap"
import { ArrowLeft } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"

export const TitleWithBackButton = ({ title, customBackFunction = null, customBackFunctionArgument = null, customBackFunctionEnabled = false }) => {

    /* Enter a title and a title bar appears with a back button that navigates you back - 1 */
    /* You can pass a custom function that should be called when the back button is clicked when customBackFunctionEnabled is true */

    const navigate = useNavigate();

    return (
    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white">
        <Col xs={2} className="p-0">
            <ArrowLeft
                size={24}
                onClick={() => customBackFunctionEnabled ? customBackFunction(customBackFunctionArgument) : navigate(-1)}
                style={{cursor: 'pointer'}}
            />
        </Col>
        <Col 
            xs={8}
            className="text-center d-flex align-items-center justify-content-center"
        >
            <h5 className="m-0">{title}</h5>
        </Col>
        <Col xs={2}>
            
        </Col>
    </Row>
    )
}