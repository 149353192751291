import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { useEffect, useState } from "react";
import { Loader } from "../../../components/loader";
import { useOutletContext } from "react-router-dom";

export const AddChildToSubscriptionModal = ({
    showAddChildModal,
    handleCloseAddChildModal,
    subModelData,
    refetch
}) => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();
    const [childFirstName, setChildFirstName] = useState("");
    const [childLastName, setChildLastName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [children, setChildren] = useState([]);
    const [selectedChild, setSelectedChild] = useState({});
    const [subAutoRenew, setSubAutoRenew] = useState(true);

    const handleChildSearch = async () => {
        if (!childFirstName && !childLastName) { return };
        setIsLoading(true);
        const response = await getRequest(`/auth/children?firstname=${childFirstName}&lastname=${childLastName}`, token);
        if (response.length > 0) {
            setChildren(response);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Es konnten keine Kinder gefunden werden.',
                toastAppearance: 'warning'
            });
            setChildren([]);
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        if (!selectedChild.id) { return };
        setIsLoading(true);
        const data = {
            userId: selectedChild.parent.id,
            childId: selectedChild.id,
            hasAutoRenew: subAutoRenew,
            ...subModelData
        }
        const response = await postRequest('/auth/subscription/add', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger' 
            });
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: `${childFirstName} wurde zum Abonnement hinzugefügt. 🥳`,
                toastAppearance: 'success' 
            });
            setIsLoading(false);
            handleCloseAddChildModal();
            refetch();
        }
    }

    return (
        <Loader isActive={isLoading}>
            <Modal
                show={showAddChildModal}
                onHide={handleCloseAddChildModal}
                centered
            >
                <Modal.Header>Kind zum Abonnement hinzufügen</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Vorname"
                                    value={childFirstName}
                                    onChange={(e) => setChildFirstName(e.target.value)}
                                ></Form.Control>
                                <Form.Control
                                    type="text"
                                    placeholder="Nachname"
                                    value={childLastName}
                                    onChange={(e) => setChildLastName(e.target.value)}
                                ></Form.Control>
                                <Button onClick={handleChildSearch}>Suchen</Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    {children.length > 0 &&
                        <>
                            <Row className="mt-3">
                                <Col xs={12}>Suchergebnisse:</Col>
                            </Row>
                            {children.map((child) => {
                                return (
                                    <Row key={child.id} className="mt-3">
                                        <Col xs={12}>
                                            <Card 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => setSelectedChild(child)}
                                                className={selectedChild?.id == child.id ? 'border-success' : ''}
                                            >
                                                <Card.Header>{child.firstName} {child.lastName}</Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={6}>
                                                            Benutzer:
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {child.parent.firstName} {child.parent.lastName}
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            })}
                             <Row className="mt-3">
                                <Col xs={6}>
                                    Abonnement automatisch verlängern?
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        className="text-end"
                                        type="switch"
                                        checked={subAutoRenew}
                                        onChange={() => setSubAutoRenew(prev => !prev)}
                                    ></Form.Check>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="me-3"
                        variant="danger"
                        onClick={handleCloseAddChildModal}
                    >
                        Abbrechen
                    </Button>
                    <Button 
                        variant="success"
                        onClick={handleSubmit}
                    >
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        </Loader>
    );
};
