import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader";
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";
import { useOutletContext } from "react-router-dom";

export const UserSubscriptions = () => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();

    const subscriptionQuery = useQuery({
        queryKey: ['user-subscription-query'],
        queryFn: () => getRequest(`/auth/subscription/user`, token)
    });
    const { data } = subscriptionQuery;

    const handleCancelSubscription = async (id) => {
        const response = await postRequest('/auth/subscription/cancel', token, {id: id});
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: !response.hasAutoRenew ? "Automatische Verlängerung aktiviert 😊" : "Automatische Verlängerung deaktiviert 🙁",
                toastAppearance: 'success'
            })
        }
        subscriptionQuery.refetch();
    }

    return (
        <Loader isActive={subscriptionQuery.isLoading || subscriptionQuery.isFetching}>
            <Container fluid>
                <TitleWithBackButton title={"Meine Abonnements"} />
            </Container>
            <Container className="pt-3 pb-3">
                {data && (
                    <>
                        <Row className="mt-3 border-bottom">
                            <Col xs={12}>
                                <h5>Meine Abonnements</h5>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            {data.length > 0 &&
                                (data.map((sub) => {
                                    return (
                                        <Col 
                                            xs={12} 
                                            md={6} 
                                            lg={4}
                                            key={sub.id}
                                            className="mb-3 mt-3"
                                        >
                                            <Card className={`h-100 ${sub.isActive ? 'border-success' : 'border-danger'}`}>
                                                <Card.Header>
                                                    <Row>
                                                        <Col xs={8}>
                                                            {sub.subscriptionModel.title}
                                                        </Col>
                                                        <Col xs={4} className="text-end">
                                                            {sub.isActive
                                                                ? <span className="text-success">Aktiv</span>
                                                                : <span className="text-danger">Inaktiv</span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    
                                                </Card.Header>
                                                <Card.Body className="no-border-on-last-child p-0 overflow-hidden">
                                                    <Row className="border-bottom p-3">
                                                        <Col xs={6}>
                                                            Abonnent:
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {sub.subscriptionModel.subUserType == 'user'
                                                                ?   `${sub.user.firstName} ${sub.user.lastName}`
                                                                :   `${sub.child.firstName} ${sub.child.lastName}`
                                                            }
                                                        </Col>
                                                    </Row>
                                                    
                                                    <Row className="border-bottom p-3">
                                                        <Col xs={6}>
                                                            Preis:
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {sub.subscriptionModel.costPerPeriod} € {`${sub.subscriptionModel.paymentRhythm == 'monthly' || sub.subscriptionModel.durationMonths == 1
                                                                ? "im Monat"
                                                                : `alle ${sub.subscriptionModel.durationMonths} Monate`
                                                            }`}
                                                        </Col>
                                                    </Row>

                                                    <Row className="border-bottom p-3">
                                                        <Col xs={6}>
                                                            Gültig ab:
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {formatDateTimeToDDMMYY(sub.validFrom)}
                                                        </Col>
                                                    </Row>

                                                    <Row className="border-bottom p-3">
                                                        <Col xs={6}>
                                                            Gültig bis:
                                                        </Col>
                                                        <Col xs={6} className="text-end">
                                                            {formatDateTimeToDDMMYY(sub.validUntil)}
                                                        </Col>
                                                    </Row>

                                                    <Row className="border-bottom p-3">
                                                        <Col xs={8}>
                                                            Automatische Verlängerung:
                                                        </Col>
                                                        <Col xs={4} className="text-end">
                                                            {sub.hasAutoRenew
                                                                ? <span className="text-success">Aktiv</span>
                                                                : <span className="text-danger">Inaktiv</span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Card.Footer className="p-3">
                                                    {sub.hasAutoRenew
                                                        ?   <Button
                                                                variant="warning"
                                                                onClick={() => handleCancelSubscription(sub.id)}
                                                            >
                                                                Auto. Verlängerung deaktivieren
                                                            </Button>
                                                        :   <Button
                                                                variant="success"
                                                                onClick={() => handleCancelSubscription(sub.id)}
                                                            >
                                                                Auto. Verlängerung aktivieren
                                                            </Button>
                                                    }
                                                    {(!sub.isActive && new Date(sub.validFrom) <= new Date()) &&
                                                        <Button className="">Abo reaktivieren</Button>
                                                    }
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    )
                                }))
                            }
                        </Row>
                    </>
                )}
            </Container>
        </Loader>
    );
};
