import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "react-bootstrap";

const SearchableDropdown = ({ optionsArray, selectedUser = {}, setSelectedUser, setInvoicePositions }) => {
    
    const [users, setUsers] = useState(optionsArray);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSelectedUser((prev) => {
            return {
                manualName: value
            }
        });
        setInvoicePositions([]);

        const filtered = users?.filter((user) =>
            `${user.firstName} ${user.lastName}`
                .toLowerCase()
                .includes(value.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleSelect = (user) => {
        setFilteredUsers([]);
        setDropdownOpen(false);
        setSelectedUser(user);
        setInvoicePositions([]);
    };

    const handleInputClick = () => {
        setDropdownOpen(true);
        setFilteredUsers(users);
    };

    useEffect(() => {
        setUsers(optionsArray);
    }, [optionsArray]);

    return (
        <div>
            <Form.Control
                type="text"
                value={selectedUser?.firstName ? `${selectedUser?.firstName} ${selectedUser?.lastName}` : selectedUser?.manualName ? selectedUser.manualName : ''}
                placeholder="Kunden suchen"
                onChange={handleInputChange}
                onClick={handleInputClick}
                onBlur={() => setTimeout(() => setDropdownOpen(false), 100)}
            />
            {dropdownOpen && filteredUsers?.length > 0 && (
                <Dropdown.Menu
                    show
                    style={{
                        display: "block",
                        position: "absolute",
                        width: "49%",
                        maxHeight: "300px",
                        overflowY: "auto",
                        zIndex: 1000,
                    }}
                >   
                    <Dropdown.Item
                        key={0}
                        onClick={() => {setSelectedUser(null); setInvoicePositions([])}}
                    >
                        Keine Auswahl
                    </Dropdown.Item>
                    {filteredUsers.map((user) => (
                        <Dropdown.Item
                            key={user.id}
                            onClick={() => handleSelect(user)}
                        >
                            {user.firstName} {user.lastName}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            )}
        </div>
    );
};

export default SearchableDropdown;
